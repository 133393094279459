import React from 'react'
import PageNav from '../Navbar/Navbar'
import { Carousel } from 'react-bootstrap'
import logo from '../../websitePictures/Logo/finallogo.png'
import styles from './OtherService.module.css'
import Footer from '../Footer/Footer'

const Design = () => {
  return (
    <div className={styles.wedCon}>
        <PageNav />
        <div className={styles.wedOther}>
            <section id="Design">
                <Carousel  data-bs-theme="dark" controls={false} indicators={false} slide={true} interval={2000}>
                  <Carousel.Item>
                    <video className={`d-block w-100 ${styles.custom_video}`} playsInline autoPlay loop muted>
                      <source src="https://res.cloudinary.com/dglpy94yq/video/upload/v1720992437/design_and_decor_page_video_d0ihgz.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <Carousel.Caption className={styles.custom_caption}>
                      <h5>Design and Decor</h5>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
            </section>
            <section>
                <div className={`container-fluid p-5 ${styles.package}`}>
                    <div className="row align-items-center justify-content-center text-center">
                        <div className="col-md-12">
                            <img src={logo} alt="perfect party planner" className='img-fluid' />
                        </div>
                        <div className="col-md-10">                         
                            <p>We believe that every event is an opportunity to create a truly immersive and unforgettable experience that delights the senses and leaves a lasting impression. That's why we're excited to introduce you to our Design & Styling services, where we specialize in crafting bespoke event atmospheres that are as unique as you are.
                            Our talented team of designers and stylists are masters at transforming spaces into works of art, seamlessly blending aesthetics, ambiance, and functionality to bring your vision to life. Whether you're dreaming of a whimsical fairy-tale wedding, a sleek a    nd modern corporate gala, or a vintage-inspired birthday party, we'll work closely with you to curate a design concept that captures your style and personality.
                            Picture an enchanting setting where every detail – from the floral arrangements and tablescapes to the lighting and decor – is thoughtfully curated to create a cohesive and visually stunning experience. With our keen eye for design trends and attention to     detail, we'll ensure that every element of your event reflects your unique vision.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`${styles.packCollection7}`}>
                <div className={`container-fluid px-md-5 mx-md-5 ${styles.cardCon}`}>
                    <div class="row align-items-center justify-content-center text-center">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <h5>Stage decor</h5>
                                </div>
                                {/*<div className="col-md-12 px-5 mb-4">
                                    <p>
                                    This package is for the couple that has done complete planning of their big day, but needs to take a sit back and relax allowing them focus on enjoying the bliss moment of the big day without worrying about their vendors delivery, this is where we come in to manage and coordinate your vendors, from handling vendors and clients meeting, to creating floor plan and timeline of activities of the day and ensuring your entire day runs smoothly and your guest fully satisfied without any glitch or underservice from your vendors.
                                    </p>
                                </div>*/}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row align-items-center justify-content-center">
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack32}`}>
                                    <div class={`card-body text-center ${styles.bdyCap}`}>
                                      <h4 class="card-title">stage decor</h4>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack33}`}>
                                    <div class={`card-body text-center ${styles.bdyCap}`}>
                                      <h4 class="card-title">stage decor</h4>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack34}`}>
                                    <div class={`card-body text-center ${styles.bdyCap}`}>
                                      <h4 class="card-title">stage decor</h4>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack35}`}>
                                    <div class={`card-body text-center ${styles.bdyCap}`}>
                                      <h4 class="card-title">stage decor</h4>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className={`${styles.packCollection5}`}>
                <div className={`container-fluid px-md-5 mx-md-5 ${styles.cardCon}`}>
                    <div class="row align-items-center justify-content-center text-center">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <h5>table settings and sittings decor</h5>
                                </div>
                                {/*<div className="col-md-12 px-5 mb-4">
                                    <p>
                                    This package is for the couple that has done complete planning of their big day, but needs to take a sit back and relax allowing them focus on enjoying the bliss moment of the big day without worrying about their vendors delivery, this is where we come in to manage and coordinate your vendors, from handling vendors and clients meeting, to creating floor plan and timeline of activities of the day and ensuring your entire day runs smoothly and your guest fully satisfied without any glitch or underservice from your vendors.
                                    </p>
                                </div>*/}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row align-items-center justify-content-center">
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack36}`}>
                                    <div class={`card-body text-center ${styles.bdyCap}`}>
                                      <h4 class="card-title">table settings and sittings decor</h4>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack37}`}>
                                    <div class={`card-body text-center ${styles.bdyCap}`}>
                                      <h4 class="card-title">table settings and sittings decor</h4>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            
            <section className={styles.packCollection6}>
                <div className={`container-fluid px-md-5 mx-md-5 ${styles.cardCon}`}>
                    <div class="row align-items-center justify-content-center text-center">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <h5>wedding reception settings</h5>
                                </div>
                                {/*<div className="col-md-12 px-5 mb-4">
                                    <p>
                                    Thinking of a full bridal package! From conceptualization, to theme creation, venue sourcing, and styling, vendor scouring, to managing and coordination of the total event. Zero stress on you, just take a seat back and relax. You want it luxuriously extravagant, or modestly beautiful. We will curate a bespoke event just the way you want it, for it’s your dream day and your ideas and wishes are valid and respected.
                                    </p>
                                </div>*/}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row align-items-center justify-content-center">
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack38}`}>
                                    <div class={`card-body text-center ${styles.bdyCap}`}>
                                      <h4 class="card-title">wedding reception settings</h4>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        <Footer />
        </div>
    </div>
  )
}

export default Design