import React from 'react'
import PageNav from '../Navbar/Navbar'
import { Carousel } from 'react-bootstrap'
import logo from '../../websitePictures/Logo/finallogo.png'
import styles from './OtherService.module.css'
import Footer from '../Footer/Footer'

const Coorporate = () => {
  return (
    <div className={styles.wedCon}>
        <PageNav />
        <div className={styles.wedOther}>
            <section id="Coorporate">
                <Carousel  data-bs-theme="dark" controls={false} indicators={false} slide={true} interval={2000}>
                  <Carousel.Item>
                    <video className={`d-block w-100 ${styles.custom_video}`} playsInline autoPlay loop muted>
                      <source src="https://res.cloudinary.com/dglpy94yq/video/upload/v1720992625/corporate_events_video_bfcptc.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <Carousel.Caption className={styles.custom_caption}>
                      <h5>Corporate events</h5>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
            </section>
            <section>
                <div className={`container-fluid p-5 ${styles.package}`}>
                    <div className="row align-items-center justify-content-center text-center">
                        <div className="col-md-12">
                            <img src={logo} alt="perfect party planner" className='img-fluid' />
                        </div>
                        <div className="col-md-10">
                            <p>At Perfect Party Planner, we understand the importance of corporate events in fostering connections, celebrating achievements, and driving business success. That's why we're delighted to extend our expertise in creating impactful and memorable corporate experiences tailored to your company's objectives. Picture a sophisticated venue that reflects your company's brand and values, innovative technology solutions that enhance engagement and interaction, and curated experiences that inspire, motivate, and entertain. With Perfect Party Planner, your corporate event will not only meet but exceed expectations, setting the stage for continued success and growth. Let's make your corporate event a resounding success that sets the stage for future achievements!</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles.packCollection2}>
                <div className={`container-fluid px-md-5 mx-md-5 ${styles.cardCon}`}>
                    <div class="row align-items-center justify-content-center text-center">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <h5>seminar</h5>
                                </div>
                                {/*<div className="col-md-12 px-5 mb-4">
                                    <p>
                                    Thinking of a full bridal package! From conceptualization, to theme creation, venue sourcing, and styling, vendor scouring, to managing and coordination of the total event. Zero stress on you, just take a seat back and relax. You want it luxuriously extravagant, or modestly beautiful. We will curate a bespoke event just the way you want it, for it’s your dream day and your ideas and wishes are valid and respected.
                                    </p>
                                </div>*/}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row align-items-center justify-content-center">
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack7}`}>
                                    <div class={`card-body text-center ${styles.bdyCap}`}>
                                      <h4 class="card-title">seminar</h4>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack8}`}>
                                    <div class={`card-body ${styles.bdyCap}`}>
                                      <h4 class="card-title text-center">seminar</h4>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack9}`}>
                                    <div class={`card-body ${styles.bdyCap}`}>
                                      <h4 class="card-title text-center">seminar</h4>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`${styles.packCollection5}`}>
                <div className={`container-fluid px-md-5 mx-md-5 ${styles.cardCon}`}>
                    <div class="row align-items-center justify-content-center text-center">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <h5>conference</h5>
                                </div>
                                {/*<div className="col-md-12 px-5 mb-4">
                                    <p>
                                    This package is for the curious couple who wants to handle part of their big day planning, but needs professionalism touch in major areas, and coaching. We are here and available for you to steer us in the direction you need our service.
                                    </p>
                                </div>*/}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row align-items-center justify-content-center">
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack10}`}>
                                    <div class={`card-body text-center ${styles.bdyCap}`}>
                                      <h4 class="card-title">conference</h4>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack11}`}>
                                    <div class={`card-body ${styles.bdyCap}`}>
                                      <h4 class="card-title text-center">conference</h4>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className={`${styles.packCollection5}`}>
                <div className={`container-fluid px-md-5 mx-md-5 ${styles.cardCon}`}>
                    <div class="row align-items-center justify-content-center text-center">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <h5>Gala dinner</h5>
                                </div>
                                {/*<div className="col-md-12 px-5 mb-4">
                                    <p>
                                    This package is for the couple that has done complete planning of their big day, but needs to take a sit back and relax allowing them focus on enjoying the bliss moment of the big day without worrying about their vendors delivery, this is where we come in to manage and coordinate your vendors, from handling vendors and clients meeting, to creating floor plan and timeline of activities of the day and ensuring your entire day runs smoothly and your guest fully satisfied without any glitch or underservice from your vendors.
                                    </p>
                                </div>*/}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row align-items-center justify-content-center">
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack12}`}>
                                    <div class={`card-body text-center ${styles.bdyCap}`}>
                                      <h4 class="card-title">gala dinner</h4>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack13}`}>
                                    <div class={`card-body ${styles.bdyCap}`}>
                                      <h4 class="card-title text-center">gala dinner</h4>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className={`${styles.packCollection6}`}>
                <div className={`container-fluid px-md-5 mx-md-5 ${styles.cardCon}`}>
                    <div class="row align-items-center justify-content-center text-center">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <h5>product launch</h5>
                                </div>
                                {/*<div className="col-md-12 px-5 mb-4">
                                    <p>
                                    This package is for the couple that has done complete planning of their big day, but needs to take a sit back and relax allowing them focus on enjoying the bliss moment of the big day without worrying about their vendors delivery, this is where we come in to manage and coordinate your vendors, from handling vendors and clients meeting, to creating floor plan and timeline of activities of the day and ensuring your entire day runs smoothly and your guest fully satisfied without any glitch or underservice from your vendors.
                                    </p>
                                </div>*/}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row align-items-center justify-content-center">
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack39}`}>
                                    <div class={`card-body text-center ${styles.bdyCap}`}>
                                      <h4 class="card-title">product launch</h4>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        <Footer />
        </div>
    </div>
  )
}

export default Coorporate