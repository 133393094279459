import React from 'react'
import styles from './HeroAbout.module.css'

const HeroAbout = () => {
  return (
    <section className={styles.heroAbtSec}>
        <div className="container text-center py-5 px-3">
            <div className={`row align-items-center justify-content-center ${styles.heroAbtCon}`}>
                <div className={`"col-md-12 py-4 ${styles.firstH}`}>
                    <h5>Crafting Unforgettable Experiences</h5>
                </div>
                <div className={`col-md-8 pb-4 ${styles.secH}`}>
                    <h5>Your Premier Destination for Exquisite Events!</h5>
                </div>
                <div className={`col-md-10 pb-4 mb-5 ${styles.thirdH}`}>
                    <h5>
                    Perfect Party Planner is an innovative event planning and management company located in
                    Lagos State Nigeria with vast reach across the country, we are your premier destination for all
                    your event planning needs. With a passion for creativity and a dedication to excellence, we
                    specialize in curating unforgettable experiences tailored to your unique vision.
                    </h5>
                </div>
            </div>
        </div>
    </section>
  )
}

export default HeroAbout