import React, { useState } from 'react';
import styles from './Contacts.module.css';
import axios from 'axios';
import PageNav from '../Navbar/Navbar';
import decor from '../../websitePictures/gallery/contactUs.jpg';
import logo from '../../websitePictures/Logo/finallogo.png';
import { Carousel } from 'react-bootstrap';
import Footer from '../Footer/Footer';

const Contacts = () => {
    const handleToggleClick = () => {
      setIsMenuOpen(!isMenuOpen);
    };

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        address: '',
        numberOfGuests: '',
        phoneNumber: '',
        date: '',
        eventType: '',
        message: '',
        other: ''
    });
    const [errors, setErrors] = useState({});
    const [serverError, setServerError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [submitting, setSubmitting] = useState(false);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!validateEmail(formData.email)) {
            newErrors.email = 'Email is invalid';
        }
        if (!formData.address) newErrors.address = 'Address is required';
        if (!formData.numberOfGuests) newErrors.numberOfGuests = 'Number of guests is required';
        if (!formData.phoneNumber) newErrors.phoneNumber = 'Phone number is required';
        if (!formData.date) newErrors.date = 'Date is required';
        if (!formData.eventType) newErrors.eventType = 'Event type is required';
        if (!formData.message) newErrors.message = 'Message is required';
        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            setErrors({});
            setSubmitting(true); 
            axios.post('https://rash-email-receiver.onrender.com/send-email', formData, {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            })
            .then(response => {
                if (response.data.errors) {
                    setErrors(response.data.errors);
                } else {
                    setSuccessMessage('Message sent successfully');
                    setFormData({
                        name: '',
                        email: '',
                        address: '',
                        numberOfGuests: '',
                        phoneNumber: '',
                        date: '',
                        eventType: '',
                        message: '',
                        other: ''
                    });
                }
            })
            .catch(error => {
                console.error('Error sending message:', error);
                setServerError('An error occurred while sending the message.');
            })
            .finally(() => {
                setSubmitting(false);
            });
        }
    };

    return (
        <>
            <PageNav className={styles.nav} handleToggleClick={handleToggleClick} isMenuOpen={isMenuOpen} />
            <div className={`${isMenuOpen ? '' : ''}`}>
                <section>
                    <Carousel data-bs-theme="dark" controls={false} indicators={false}>
                      <Carousel.Item>
                        <img
                          className={`d-block w-100 ${styles.custom_img}`}
                          src={decor}
                          alt="event slide"
                        />
                        <Carousel.Caption className={styles.custom_caption}>
                          <h5>Contact Us</h5>
                        </Carousel.Caption>
                      </Carousel.Item>
                    </Carousel>
                </section>
                <section className={styles.contactsCon}>
                    <div className="container py-5 mt-5 mb-5 px-3">
                        <div className="row justify-content-between">
                            <div className="col-md-4 pt-4 pb-5">
                                <div className="row">
                                    <div className={`col-md-12 ${styles.contactLogo}`}>
                                        <img src={logo} alt="perfect party planner" />
                                    </div>
                                    <div className={`col-md-12 ${styles.firstColContact}`}>
                                        <h5>
                                        We would love to hear more about your event, please fill out our contact form.
                                        <br />
                                        We look forward to connecting with you.
                                        </h5>
                                    </div>
                                    <div className={`col-md-12 pt-4 ${styles.firstColContact}`}>
                                        <h5>
                                            By Appointment Only
                                            <br />
                                            Call: +234 916 641 4943
                                        </h5>
                                    </div>
                                    <div className={`col-md-12 pt-4 ${styles.contactEmail}`}>
                                        <h5>
                                        Email: <span>perfectparty.planner23@gmail.com</span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row">
                                                
                                                <label htmlFor="eventType" className={`pt-4 ${styles.nameLabel}`}>What type of event are you having?</label>
                                                
                                                <div className="col-md-12">
                                                    <select
                                                        className={styles.input}
                                                        id="eventType"
                                                        name="eventType"
                                                        value={formData.eventType}
                                                        onChange={handleChange}
                                                        required
                                                    >
                                                        <option value="">please select</option>
                                                        <option value="Wedding">Weddings</option>
                                                        <option value="Corporate Event">Corporate Event</option>
                                                        <option value="Birthdays">Birthdays</option>
                                                        <option value="Event Design and Styling">Event Design and Styling</option>
                                                        <option value="Intimate Event">Intimate Event</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                    <div className="col-md-12 pt-3">
                                                        {errors.eventType && <div className="p-3 mb-2 bg-danger-subtle text-danger-emphasis">{errors.eventType}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    
                                                    <label htmlFor="other" className={`pt-4 ${styles.nameLabel}`}>if other please specify</label>
                                                    
                                                    <div className="col-md-12">
                                                        <input
                                                            className={`px-3 ${styles.input}`}
                                                            id="other"
                                                            name="other"
                                                            rows="2"
                                                            cols="50"
                                                            value={formData.other}
                                                            onChange={handleChange}
                                                        ></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    
                                                    <label htmlFor="name" className={`pt-4 ${styles.nameLabel}`}>Full Name:</label>
                                                    
                                                    <div className="col-md-12">
                                                        <input
                                                            className={styles.input}
                                                            type="text"
                                                            id="name"
                                                            name="name"
                                                            value={formData.name}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                        <div className="col-md-12 pt-3">
                                                            {errors.name && <div className="p-3 mb-2 bg-danger-subtle text-danger-emphasis">{errors.name}</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row">

                                                    <label htmlFor="phoneNumber" className={`pt-4 ${styles.nameLabel}`}>Phone/Mobile Number:</label>
                                                    
                                                    <div className="col-md-12">
                                                        <input
                                                            className={styles.input}
                                                            type="text"
                                                            id="phoneNumber"
                                                            name="phoneNumber"
                                                            value={formData.phoneNumber}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                        <div className="col-md-12 pt-3">
                                                            {errors.phoneNumber && <div className="p-3 mb-2 bg-danger-subtle text-danger-emphasis">{errors.phoneNumber}</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    
                                                    <label htmlFor="date" className={`pt-4 ${styles.nameLabel}`}>Date of Event:</label>
                                                    
                                                    <div className="col-md-12">
                                                        <input
                                                            className={styles.input}
                                                            type="date"
                                                            id="date"
                                                            name="date"
                                                            value={formData.date}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                        <div className="col-md-12 pt-3">
                                                            {errors.date && <div className="p-3 mb-2 bg-danger-subtle text-danger-emphasis">{errors.date}</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row">

                                                    <label htmlFor="numberOfGuests" className={`pt-4 ${styles.nameLabel}`}>Number of Guests:</label>
                                                    
                                                    <div className="col-md-12">
                                                        <input
                                                            className={styles.input}
                                                            type="number"
                                                            id="numberOfGuests"
                                                            name="numberOfGuests"
                                                            value={formData.numberOfGuests}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                        <div className="col-md-12 pt-3">
                                                            {errors.numberOfGuests && <div className="p-3 mb-2 bg-danger-subtle text-danger-emphasis">{errors.numberOfGuests}</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row">

                                                <label htmlFor="address" className={`pt-4 ${styles.nameLabel}`}>Event Location</label>
                                                
                                                <div className="col-md-12">
                                                    <input
                                                        className={styles.input}
                                                        type="text"
                                                        id="address"
                                                        name="address"
                                                        value={formData.address}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    <div className="col-md-12 pt-3">
                                                        {errors.address && <div className="p-3 mb-2 bg-danger-subtle text-danger-emphasis">{errors.address}</div>}
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    
                                                    <label htmlFor="email" className={`pt-4 ${styles.nameLabel}`}>Email:</label>
                                                    
                                                    <div className="col-md-12">
                                                        <input
                                                            className={styles.input}
                                                            type="email"
                                                            id="email"
                                                            name="email"
                                                            value={formData.email}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                    <div className='col-md-12 pt-3'>
                                                        {errors.email && <div className="p-3 mb-2 bg-danger-subtle text-danger-emphasis">{errors.email}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            <label htmlFor="message" className={`pt-4 ${styles.nameLabel}`}>Message:</label>
                                            
                                            <div className="col-md-12">
                                                <textarea
                                                    className={`px-3 ${styles.textArea}`}
                                                    id="message"
                                                    name="message"
                                                    rows="4"
                                                    cols="50"
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                    required
                                                ></textarea>
                                            </div>
                                            <div className='col-md-12 pt-3'>
                                                {errors.message && <div className="p-3 mb-2 bg-danger-subtle text-danger-emphasis">{errors.message}</div>}
                                            </div>
                                            <div className="col-md-12 pt-3">
                                                {serverError && <div className="p-3 mb-2 bg-danger-subtle text-danger-emphasis">{serverError}</div>}
                                                {successMessage && <div className="p-3 mb-2 bg-success-subtle text-success-emphasis">{successMessage}</div>}
                                            </div>
                                            <div className="col-md-12 py-4 px-3 w-100">
                                                <button type="submit" className={`${styles.contactBtn}`} disabled={submitting}>
                                                    {submitting ? "Submitting..." : "Contact Us"}
                                                </button>
                                            </div>
                                    </div>
                                </form>
                            </div>
                        </div>  
                    </div>
                </section>
                <Footer />
            </div>
        </>
    );
}

export default Contacts;