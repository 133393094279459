import React, { useEffect, useState } from 'react'
import PageNav from '../Navbar/Navbar'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Carousel } from 'react-bootstrap'
import logo from '../../websitePictures/Logo/finallogo.png'
import styles from './HowWeWork.module.css'
import Footer from '../Footer/Footer'

const OurServices = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleToggleClick = () => {
      setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        AOS.init({
            duration: 900, // duration of animation in ms
        });
    }, []);

  return (
    <div className={styles.ourCon}>
    <PageNav className={styles.nav} handleToggleClick={handleToggleClick} isMenuOpen={isMenuOpen} />
        <div className={`${styles.other}`}>
            <section id='our_services'>
                <Carousel  data-bs-theme="dark" controls={false} indicators={false} slide={true} interval={2000}>
                  <Carousel.Item>
                    <video className={`d-block w-100 ${styles.custom_video}`} playsInline autoPlay loop muted>
                      <source src="https://res.cloudinary.com/dglpy94yq/video/upload/v1720992458/how_we_work_page_video_wwzlte.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <Carousel.Caption className={styles.custom_caption}>
                      <h5>how we work</h5>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
            </section>
            <section>
              <div className="container">
                <div className="row d-flex justify-content-center">
                  <div className={`col-md-12 text-center ${styles.vertline}`}>
                    <div className={styles.line}></div>
                  </div>
                  <div className="col-md-12 text-center">
                    <img src={logo} alt="perfect party planner" width={200} height={200} className="img-fluid" />
                  </div>
                  <div className={`col-md-12 text-center mb-3 ${styles.abtPro}`}>
                    <h5
                    data-aos="fade-up"
                    data-aos-easing="ease-in"
                    >Learn about our process and approach</h5>
                    <p
                    data-aos="fade-up"
                    data-aos-easing="ease-in"
                    >
                      We oversee every aspect of the event, from the major elements to the finest details
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section>
                <div className={`container-fluid ${styles.simpCon}`}>
                    <div className="row d-inline-flex align-items-center justify-content-center">
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-12 mt-md-5 pt-md-5 mt-0 pt-0">
                                    <div className={styles.horizntaline}></div>
                                </div>
                                <div className={`col-md-12 mt-md-4 mt-0 ${styles.simpTitle}`}>
                                    <div className="row">
                                        <div className="col-md-12 order-md-1 order-2">
                                            <h5
                                            data-aos="fade-up"
                                            data-aos-easing="ease-in"
                                            >Consultation</h5>
                                        </div>
                                        {/* <div className="col-md-12 order-md-2 order-3">
                                            <h4
                                            data-aos="fade-up"
                                            data-aos-easing="ease-in"
                                            >design & decor</h4>
                                        </div> */}
                                        <div className="col-md-12 order-1 order-md-3">
                                            <p
                                            data-aos="fade-up"
                                            data-aos-easing="ease-in"
                                            >01</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`col-md-5`}>
                            <Carousel data-bs-theme="dark" controls={false} indicators={false}>
                              <Carousel.Item>
                                <img
                                  className={`d-block w-100 ${styles.custom_img}`}
                                  src="https://res.cloudinary.com/dglpy94yq/image/upload/v1721560234/d3sign_bctslq.jpg"
                                  alt="event slide"
                                />
                              </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
                <div className={`container-fluid py-5 px-3 ${styles.disc}`}>
                    <div className="row d-flex justify-content-center">
                        <div className={`col-md-6 d-flex ${styles.simpDisc}`}>
                            <div>
                                <div className={`mx-3 ${styles.line}`}></div>
                            </div>
                            <h5
                            data-aos="fade-up"
                            data-aos-easing="ease-in"
                            >
                            Consultation: In the initial step, we engage in a detailed consultation with the client to understand their vision, preferences, and requirements for the event.
                            We discuss their goals, theme ideas, budget, and any specific details they want to incorporate. This consultation serves as the foundation for our collaboration.
                            </h5>
                        </div>
                    </div>
                </div>
            </section>
            
            <section>
                <div className={`container-fluid ${styles.simpCon1}`}>
                    <div className="row d-inline-flex align-items-center justify-content-center">
                        <div className="col-md-4 order-2">
                            <div className="row">
                                <div className="col-md-12 mt-md-5 pt-md-5 mt-0 pt-0">
                                    <div className={styles.horizntaline}></div>
                                </div>
                                <div className={`col-md-12 mt-4 ${styles.simpTitle1}`}>
                                    <div className="row">
                                        <div className="col-md-12 order-md-1 order-2">
                                            <h5
                                            data-aos="fade-up"
                                            data-aos-easing="ease-in"
                                            >Planning</h5>
                                        </div>
                                        <div className="col-md-12 order-md-2 order-3">
                                            <h4
                                            data-aos="fade-up"
                                            data-aos-easing="ease-in"
                                            >Production</h4>
                                        </div>
                                        <div className="col-md-12 order-md-3 order-1">
                                            <p
                                            data-aos="fade-up"
                                            data-aos-easing="ease-in"
                                            >02</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`col-md-5 order-md-1 order-2`}>
                            <Carousel data-bs-theme="dark" controls={false} indicators={false}>
                              <Carousel.Item>
                                <img
                                  className={`d-block w-100 ${styles.custom_img}`}
                                  src="https://res.cloudinary.com/dglpy94yq/image/upload/v1721560234/planning_dcuhbr.jpg"
                                  alt="event slide"
                                />
                              </Carousel.Item>
                              <Carousel.Item>
                                <img
                                  className={`d-block w-100 ${styles.custom_img}`}
                                  src="https://res.cloudinary.com/dglpy94yq/image/upload/v1721560234/production1_buyhit.jpg"
                                  alt="event slide"
                                />
                              </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
                <div className={`container-fluid py-5 px-3 ${styles.disc}`}>
                    <div className="row d-flex justify-content-center">
                        <div className={`col-md-6 d-flex ${styles.simpDisc}`}>
                            <div>
                                <div className={`mx-3 ${styles.line}`}></div>
                            </div>
                            <h5
                            data-aos="fade-up"
                            data-aos-easing="ease-in"
                            >
                            Planning and Design: After gaining a clear understanding of the client's needs, our event planners work meticulously to create a comprehensive plan and design concept. This includes venue selection, logistical arrangements, vendor coordination, and creating a timeline for the event. We present the client with a detailed proposal, complete with visual inspirations, floor plans, and decor options, ensuring their vision is brought to life.
                            </h5>
                        </div>
                    </div>
                </div>
            </section>
            
            <section>
                <div className={`container-fluid ${styles.simpCon2}`}>
                    <div className="row d-inline-flex align-items-center justify-content-center">
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-12 mt-md-5 pt-md-5 mt-0 pt-0">
                                    <div className={styles.horizntaline}></div>
                                </div>
                                <div className={`col-md-12 mt-md-4 mt-0 ${styles.simpTitle2}`}>
                                    <div className="row">
                                        <div className="col-md-12 order-md-1 order-2">
                                            <h5
                                            data-aos="fade-up"
                                            data-aos-easing="ease-in"
                                            >Management</h5>
                                        </div>
                                        <div className="col-md-12 order-md-2 order-1">
                                            <p
                                            data-aos="fade-up"
                                            data-aos-easing="ease-in"
                                            >03</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`col-md-5 ${styles.imgContainer}`}>
                        </div>
                    </div>
                </div>
                <div className={`container-fluid py-5 px-3 ${styles.disc}`}>
                    <div className="row d-flex justify-content-center">
                        <div className={`col-md-6 d-flex ${styles.simpDisc}`}>
                            <div>
                                <div className={`mx-3 ${styles.line}`}></div>
                            </div>
                            <h5
                            data-aos="fade-up"
                            data-aos-easing="ease-in"
                            >
                            Execution and Coordination: Once the planning and design are finalized, we proceed to execute the event. Our team takes care of all the arrangements, coordinating with vendors, managing logistics, and overseeing the event's smooth operation. We ensure that every detail is executed flawlessly, allowing the client to relax and enjoy their special occasion while we take care of the event's coordination and execution.
                            </h5>
                        </div>
                    </div>
                </div>
            </section>
            
            {/*<section>
                <div className={`container-fluid ${styles.simpCon}`}>
                    <div className="row d-inline-flex align-items-center justify-content-center">
                        <div className="col-md-4 order-2">
                            <div className="row">
                                <div className="col-md-12 mt-md-5 pt-md-5 mt-0 pt-0">
                                    <div className={styles.horizntaline3}></div>
                                </div>
                                <div className={`col-md-12 mt-4 ${styles.simpTitle3}`}>
                                    <div className="row">
                                        <div className="col-md-12 order-md-1 order-2">
                                            <h5
                                            data-aos="fade-up"
                                            data-aos-easing="ease-in"
                                            >Design</h5>
                                        </div>
                                        <div className="col-md-12 order-md-2 order-3">
                                            <h4
                                            data-aos="fade-up"
                                            data-aos-easing="ease-in"
                                            >& Decor</h4>
                                        </div>
                                        <div className="col-md-12 order-md-3 order-1">
                                            <p
                                            data-aos="fade-up"
                                            data-aos-easing="ease-in"
                                            >04</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 order-md-1 order-2">
                            <img src={wedding} className='img-fluid' alt="perfect wedding" />
                        </div>
                    </div>
                </div>
                <div className={`container-fluid py-5 px-3 ${styles.disc}`}>
                    <div className="row d-flex justify-content-center">
                        <div className={`col-md-6 d-flex ${styles.simpDisc}`}>
                            <div>
                                <div className={`mx-3 ${styles.line}`}></div>
                            </div>
                            <h5>
                            Consultation: In the initial step, we engage in a detailed consultation with the client to understand their vision, preferences, and requirements for the event.
                            We discuss their goals, theme ideas, budget, and any specific details they want to incorporate. This consultation serves as the foundation for our collaboration.
                            </h5>
                        </div>
                    </div>
                </div>
            </section>*/}

            <Footer />
            
        </div>
    </div>
  )
}

export default OurServices