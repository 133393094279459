import React, { useState } from 'react'
import decor from '../../websitePictures/newImages/who_we_are.jpg'
import PageNav from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import styles from './WhoWeAre.module.css'
import { Card, Carousel } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import any from '../../websitePictures/newImages/back1.jpg'

const WhoWeAre = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleToggleClick = () => {
      setIsMenuOpen(!isMenuOpen);
    };  
    /** check if button work */

  return (
    <>
        <PageNav className={styles.nav} handleToggleClick={handleToggleClick} isMenuOpen={isMenuOpen} />
        <div className={`${styles.whoCon}`}>
            <section className={styles.howFirstCon} id='who_we_are'>
                <Carousel data-bs-theme="dark" controls={false} indicators={false}>
                  <Carousel.Item>
                    <img
                      className={`d-block w-100 ${styles.custom_img}`}
                      src={decor}
                      alt="event slide"
                    />
                    <Carousel.Caption className={styles.custom_caption}>
                      <h5>Who We Are</h5>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
            </section>
            <section className={styles.howSecCon}>
                <div className="container text-center py-5 px-3">
                    <div className="row align-items-center justify-content-center">
                        <div className={`col-md-12 ${styles.whoBdy}`}>
                            <h5>
                            Perfect Party Planner is an innovative event planning and management company located in
                            Lagos State Nigeria with vast reach across the country, we are your premier destination for all
                            your event planning needs. With a passion for creativity and a dedication to excellence, we
                            specialize in curating unforgettable experiences tailored to your unique vision.
                            At Perfect Party Planner, we understand that every event is a milestone worthy of celebration.
                            We have the expertise and resources to handle every aspect of your event with precision and
                            flair.
                            </h5>
                        </div>
                    </div>
                </div>
            </section>
            <section className={styles.thirdSecCon}>
                <div className="container text-center mt-5 py-3 px-3">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-5">
                            <div className="row align-items-center justify-content-center">
                                <div className={`col-md-6 text-center ${styles.missionHead}`}>
                                    <h5>Vision</h5>
                                </div>
                                <div className={`col-md-12 pb-3 ${styles.missionBdy}`}>
                                    <h5>
                                    To be the beacon of innovation and excellence in event planning, orchestrating unforgettable
                                    experiences that transcend imagination and leave an indelible mark on every occasion
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="row align-items-center justify-content-center">
                                <div className={`col-md-6 ${styles.missionHead}`}>
                                    <h5>Mission</h5>
                                </div>
                                <div className={`col-md-12 pb-3 ${styles.missionBdy}`}>
                                    <h5>Our mission is to exceed
                                    expectations, inspire delight, and etch unforgettable experiences into the fabric of each event
                                    we touch, leaving a legacy of unparalleled excellence.
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={styles.staticCon}>
                <img src={any} alt="event" />
            </section>
            <section className={styles.coreSec}>
                <div className="container text-center">
                    <div className="row align-items-center justify-content-center">
                        <div className={`col-md-12 py-4 px-3 mb-5 ${styles.philoH}`}>
                            <h5>Our work philosophy </h5>
                        </div>
                        <div className="col-md-12 py-4 px-3 text-center">
                            <div className={`row ${styles.OurCoreSec}`}>
                                <Card style={{ width: '15rem', marginBottom: '1rem', border: '2px solid black', borderRadius: '0', backgroundColor: 'transparent' }}>
                                  <Card.Body>
                                    <Card.Title className={`p-3 ${styles.cardH}`}>Professionalism</Card.Title>
                                  </Card.Body>
                                </Card>
                                <Card style={{ width: '15rem', marginBottom: '1rem', border: '2px solid black', borderRadius: '0', backgroundColor: 'transparent' }}>
                                  <Card.Body>
                                    <Card.Title className={`p-3 ${styles.cardH}`}>Creativity</Card.Title>
                                  </Card.Body>
                                </Card>
                                <Card style={{ width: '15rem', marginBottom: '1rem', border: '2px solid black', borderRadius: '0', backgroundColor: 'transparent' }}>
                                  <Card.Body>
                                    <Card.Title className={`p-3 ${styles.cardH}`}>Excellence</Card.Title>
                                  </Card.Body>
                                </Card>
                                <Card style={{ width: '20rem', marginBottom: '1rem', border: '2px solid black', borderRadius: '0', backgroundColor: 'transparent' }}>
                                  <Card.Body>
                                    <Card.Title className={`p-3 ${styles.cardH}`}>Customer Satisfaction</Card.Title>
                                  </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={styles.forthSecCon}>
                <div className="container text-center py-5 px-3">
                    <div className="row align-items-center justify-content-center">
                        <div className={`col-md-6 ${styles.approachHead}`}>
                            <h5>our approach</h5>
                        </div>
                        <div className={`col-md-12 pt-5 ${styles.approachBdy}`}>
                            <h5>
                            We believe that no two
                            events are alike, which is why we take the time to understand your preferences, budget, and
                            objectives. Our collaborative process ensures that every element, from venue selection and
                            décor to entertainment and catering, is carefully curated to exceed your expectations.
                            </h5>
                        </div>
                        <div className={`col-md-12 py-5`}>
                            <Link to={"/contact"}>
                                <button className={styles.approachBtn}>
                                    schedule a consultation
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    </>
  )
}

export default WhoWeAre