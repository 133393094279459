import React from 'react'
import PageNav from '../Navbar/Navbar'
import { Carousel } from 'react-bootstrap'
import logo from '../../websitePictures/Logo/finallogo.png'
import styles from './OtherService.module.css'
import Footer from '../Footer/Footer'

const Wedding = () => {
  return (
    <div className={styles.wedCon}>
        <PageNav />
        <div className={styles.wedOther}>
            <section id="wedding">
                <Carousel  data-bs-theme="dark" controls={false} indicators={false} slide={true} interval={2000}>
                  <Carousel.Item>
                    <video className={`d-block w-100 ${styles.custom_video}`} playsInline autoPlay loop muted>
                      <source src="https://res.cloudinary.com/dglpy94yq/video/upload/v1720993288/use_this_video_for_the_weddings_under_services_iadgyp.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <Carousel.Caption className={styles.custom_caption}>
                      <h5>weddings</h5>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
            </section>
            <section>
                <div className={`container-fluid ${styles.package}`}>
                    <div className="row align-items-center justify-content-center text-center">
                        <div className="col-md-12">
                            <img src={logo} alt="perfect party planner" className='img-fluid' />
                        </div>
                        {/*<div className="col-md-12">
                            <h4>wedding packages</h4>
                        </div>*/}
                    </div>
                </div>
            </section>

            <section className={styles.packCollection1}>
                <div className={`container-fluid ${styles.cardCon}`}>
                    <div class="row align-items-center justify-content-center text-center">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <h5>full bridal package</h5>
                                </div>
                                <div className="col-md-12 px-5 mb-4">
                                    <p>
                                    Thinking of a full bridal package! From conceptualization, to theme creation, venue sourcing, and styling, vendor scouring, to managing and coordination of the total event. Zero stress on you, just take a seat back and relax. You want it luxuriously extravagant, or modestly beautiful. We will curate a bespoke event just the way you want it, for it’s your dream day and your ideas and wishes are valid and respected.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row align-items-center justify-content-center">
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack}`}>
                                    <div class={`card-body text-center ${styles.bdyCap}`}>
                                      <h4 class="card-title">wedding</h4>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack1}`}>
                                    <div class={`card-body ${styles.bdyCap}`}>
                                      <h4 class="card-title text-center">wedding</h4>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack3}`}>
                                    <div class={`card-body ${styles.bdyCap}`}>
                                      <h4 class="card-title text-center">wedding</h4>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`${styles.packCollection}`}>
                <div className={`container-fluid mt-5 pt-3 ${styles.cardCon}`}>
                    <div class="row align-items-center justify-content-center text-center">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <h5>PARTIAL BRIDAL PLANNING</h5>
                                </div>
                                <div className="col-md-12 px-5 mb-4">
                                    <p>
                                    This package is for the curious couple who wants to handle part of their big day planning, but needs professionalism touch in major areas, and coaching. We are here and available for you to steer us in the direction you need our service.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row align-items-center justify-content-center">
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack2}`}>
                                    <div class={`card-body text-center ${styles.bdyCap}`}>
                                      <h4 class="card-title">vow renewal</h4>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack4}`}>
                                    <div class={`card-body ${styles.bdyCap}`}>
                                      <h4 class="card-title text-center">vow renewal</h4>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className={`${styles.packCollection1}`}>
                <div className={`container-fluid mt-5 pt-3 ${styles.cardCon}`}>
                    <div class="row align-items-center justify-content-center text-center">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <h5>DAY OF WEDDING COORDINATION</h5>
                                </div>
                                <div className="col-md-12 px-5 mb-4">
                                    <p>
                                    This package is for the couple that has done complete planning of their big day, but needs to take a sit back and relax allowing them focus on enjoying the bliss moment of the big day without worrying about their vendors delivery, this is where we come in to manage and coordinate your vendors, from handling vendors and clients meeting, to creating floor plan and timeline of activities of the day and ensuring your entire day runs smoothly and your guest fully satisfied without any glitch or underservice from your vendors.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row align-items-center justify-content-center">
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack5}`}>
                                    <div class={`card-body text-center ${styles.bdyCap}`}>
                                      <h4 class="card-title">destination wedding</h4>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack6}`}>
                                    <div class={`card-body ${styles.bdyCap}`}>
                                      <h4 class="card-title text-center">destination wedding</h4>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        <Footer />
        </div>
    </div>
  )
}

export default Wedding