import React from 'react'
import { Carousel } from 'react-bootstrap'
//import home from '../../websitePictures/newImages/home.jpg'
//import home1 from '../../websitePictures/newImages/home1.jpg'
//import home2 from '../../websitePictures/newImages/home2.jpg'
//import home3 from '../../websitePictures/newImages/home3.png'
//import home4 from '../../websitePictures/newImages/homepage.jpg'
import styles from './Hero.module.css'

const Hero = () => {
  return (
    <section className={styles.hero} id='manifesto'>    
            <Carousel data-bs-theme="dark" controls={false} indicators={false} slide={true} interval={2000}>
              <Carousel.Item>
                <video className={`d-block w-100 ${styles.custom_video}`} playsInline autoPlay loop muted>
                  <source src="https://res.cloudinary.com/dglpy94yq/video/upload/v1720384382/4bc7f0f8ef72e50f85b88b3728eaa1da_t3_vupf9v.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <Carousel.Caption className={styles.custom_caption}>
                  <h5>Perfect Party Planner</h5>
                  <p>event planner in nigeria</p>
                </Carousel.Caption>
              </Carousel.Item>
              {/*<Carousel.Item>
                <img
                  className={`d-block w-100 ${styles.custom_img}`}
                  src={home3}
                  alt="event slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className={`d-block w-100 ${styles.custom_img}`}
                  src={home4}
                  alt="event slide"
                />
              </Carousel.Item>*/}
            </Carousel>
    </section>
  )
}

export default Hero