import React, { useState } from 'react'
import PageNav from './Navbar/Navbar';
import Hero from './Hero/Hero';
import styles from './Rootcomponent.module.css'
import HeroAbout from './HeroAbout/HeroAbout';
import About from './About/About';
import Footer from './Footer/Footer';


const RootComponent = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggleClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={styles.rootCom}>
      <PageNav handleToggleClick={handleToggleClick} isMenuOpen={isMenuOpen} />
      <div className={styles.others}>
        <Hero />
        <HeroAbout />
        <About />
        <Footer />
      </div>
    </div>
  )
}

export default RootComponent