import React from 'react'
import center1 from '../../websitePictures/gallery/center.jpg'
import center from '../../websitePictures/newImages/how_we_work.jpg'
import styles from './About.module.css'
import { Link } from 'react-router-dom'

const About = () => {
  return (
    <div className={styles.aboutCon}>
        <section id='about'>
            <div className="container text-center pt-3 pb-3 px-3 my-5">
                <div className="row row align-items-center justify-content-center">
                    <div className="col-md-6">
                        <div className="row">
                            <div className={`col-12 pt-3 pb-3 ${styles.abtFirstH}`}>
                                <h5>About Us</h5>
                            </div>
                            <dic className={`col-12 pb-3 ${styles.abtSecH}`}>
                                <h5>Perfect Party Planner</h5>
                            </dic>
                            <div className={`col-12 pb-5 ${styles.abtThirdH}`}>
                                <h5>At Perfect Party Planner, we understand that every event is a milestone worthy of celebration.
                                We have the expertise and resources to handle every aspect of your event with precision and
                                flair.
                                </h5>
                            </div>
                            <div className="col-md-12 pb-5">
                                <Link to={"/services"}>
                                    <button className={styles.aboutBtn}>
                                        Learn More
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={`col-md-6 ${styles.firstBack}`}>
                        <img src={center1} alt="event" className='img-fluid' />
                    </div>
                </div>
            </div>
        </section>
        <section id='how_we_work'>
            <div className="container text-center py-5">
                <div className="row row align-items-center justify-content-center">
                    <div className="col-md-6 order-md-2">
                        <div className="row">
                            <div className={`col-12 pb-3 pt-3 ${styles.abtFirstH}`}>
                                <h5>How We Work</h5>
                            </div>
                            <dic className={`col-12 pb-3 ${styles.abtSecH}`}>
                                <h5>Perfect Party Planner</h5>
                            </dic>
                            <div className={`col-12 pb-5 ${styles.abtThirdH}`}>
                                <h5>
                                What sets us apart is our attention to detail and personalized approach. We believe that no two
                                events are alike, which is why we take the time to understand your preferences, budget, and
                                objectives. Our collaborative process ensures that every element, from venue selection and
                                décor to entertainment and catering, is carefully curated to exceed your expectations.
                                </h5>
                            </div>
                            <div className="col-md-12 pb-5">
                                <Link to={"/services"}>
                                    <button className={styles.aboutBtn}>
                                        Learn More
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={`col-md-6 order-md-1 ${styles.secBack}`}>
                        <img src={center} alt="event" className='img-fluid' />
                    </div>
                </div>
            </div>
        </section>
        <section className={styles.scheduleCon}>
            <div className="container text-center py-5">
                <div className="row align-items-center justify-content-center">
                    <div className={`col-12 pb-3 pt-3 ${styles.abtFirstH}`}>
                        <h5>
                            Have a party coming up
                        </h5>
                    </div>
                    <div className={`col-md-8 pb-3 ${styles.abtDiscover}`}>
                        <h5>
                            Contact us today to start planning your perfect party!
                        </h5>
                    </div>
                    <div className="col-md-12 pt-5">
                        <Link to={"/contact"}>
                            <button className={styles.scheduleBtn}>
                                Schedule a consultation
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default About