import './App.css';
import { Route, Routes } from 'react-router';
import Contacts from './Components/Contacts/Contacts';
import RootComponent from './Components/RootComponent';
import WhoWeAre from './Components/WhoWeAre/WhoWeAre';
import HowWeWork from './Components/HowWeWork/HowWeWork';
import OurServices from './Components/OurServices/OurServices';
import ScrollToTop from './Components/ScrollToTop';
import Wedding from './Components/OtherServices/wedding';
import Coorporate from './Components/OtherServices/Corporate';
import Intimate from './Components/OtherServices/Intimate';
import Social from './Components/OtherServices/Social';
import Design from './Components/OtherServices/Design';

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<RootComponent />} />
        <Route path='/contact' element={<Contacts />} />
        <Route path='/about' element={<WhoWeAre />} />
        <Route path='/our_services' element={<OurServices />} />
        <Route path='/services' element={<HowWeWork />} />
        <Route path='/wedding' element={<Wedding />} />
        <Route path='/corporate' element={<Coorporate />} />
        <Route path='/intimate' element={<Intimate />} />
        <Route path='/social' element={<Social />} />
        <Route path='/design' element={<Design />} />
      </Routes>
    </>
  );
}

export default App;
