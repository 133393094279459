import React, { useState } from 'react'
import styles from './OurServices.module.css'
import reception from '../../websitePictures/newImages/back.jpg'
import design from '../../websitePictures/newImages/design&style.jpg'
import design1 from '../../websitePictures/newImages/design&style2.jpg'
import design2 from '../../websitePictures/newImages/design&style3.jpg'
import corporate from '../../websitePictures/gallery/corporate.jpg'
import celebration from '../../websitePictures/newImages/celebration_zone.jpg'
import proposal from '../../websitePictures/gallery/proposal.jpg'
import { Card, CardGroup, Carousel } from 'react-bootstrap'
import PageNav from '../Navbar/Navbar'
import Footer from '../Footer/Footer'

const HowWeWork = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleToggleClick = () => {
      setIsMenuOpen(!isMenuOpen);
    };

  return (
    <div className={styles.workCon}>
        <PageNav className={styles.nav} handleToggleClick={handleToggleClick} isMenuOpen={isMenuOpen} />
        <div className={`${styles.mainCon}`}>
            <section id='how_we_work'>
                <Carousel data-bs-theme="dark" controls={false} indicators={false}>
                  <Carousel.Item>
                    <img
                      className={`d-block w-100 ${styles.custom_img}`}
                      src={reception}
                      alt="event slide"
                    />
                    <Carousel.Caption className={styles.custom_caption}>
                      <h5>Services</h5>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
            </section>
            <section className={styles.howWeCon}>
                <div className="container text-center">
                    <div className="row align-items-center justify-content-center">
                        <div className={`col-md-12 ${styles.howWe}`}>
                            <h5>
                            Our work philosophy revolves around Professionalism, Creativity, Excellence and Customer
                            Satisfaction.
                            We have tailored our services to meet the needs of every client, each service is unique to us,
                            handled with passion and delivered with professionalism, exceeding client’s expectations
                            </h5>
                        </div>
                    </div>
                </div>
            </section>
            <section id='our_services' className={styles.ourServiceCon}>
                <div className="container py-5 px-3 text-center">
                    <div className="row align-items-center justify-center-center">
                        <div className={`col-md-12 py-4 px-3 ${styles.ourService}`}>
                            <h5>
                                Our Services
                            </h5>
                        </div>
                        <div className="col-md-12 py-4 px-3">
                            <CardGroup className={`text-center`}>
                              <Card className={styles.serveCard}>
                                <Card.Body>
                                  <Card.Title className={`${styles.cardHead}`}>Weddings</Card.Title>
                                </Card.Body>
                              </Card>
                              <Card className={styles.serveCard}>
                                <Card.Body>
                                  <Card.Title className={styles.cardHead}>Destination Weddings</Card.Title>
                                </Card.Body>
                              </Card>
                              <Card className={styles.serveCard}>
                                <Card.Body>
                                  <Card.Title className={styles.cardHead}> Vow Renewals</Card.Title>
                                </Card.Body>
                              </Card>
                            </CardGroup>
                        </div>
                        <div className="col-md-12 py-4 px-3 text-center">
                            <div className={`row ${styles.wedSecCon}`}>
                                <Card style={{ width: '22rem', backgroundColor: "#44585b", marginBottom: '1rem' }}>
                                  <Card.Body>
                                    <Card.Title className={`p-3 ${styles.cardH}`}>FULL BRIDAL PACKAGE</Card.Title>
                                    <Card.Text className={styles.cardText}>
                                    Thinking of a full bridal package! From conceptualization, to theme creation, venue sourcing,
                                    and styling, vendor scouring, to managing and coordination of the total event. Zero stress on
                                    you, just take a seat back and relax. You want it luxuriously extravagant, or modestly beautiful.
                                    We will curate a bespoke event just the way you want it, for it’s your dream day and your ideas
                                    and wishes are valid and respected.
                                    </Card.Text>
                                  </Card.Body>
                                </Card>
                                <Card style={{ width: '22rem', backgroundColor: "#392b37", marginBottom: '1rem' }}>
                                  <Card.Body>
                                    <Card.Title className={`p-3 ${styles.cardH}`}>PARTIAL BRIDAL PLANNING</Card.Title>
                                    <Card.Text className={styles.cardText}>
                                    This package is for the curious couple who wants to handle part of their big day planning, but
                                    needs professionalism touch in major areas, and coaching. We are here and available for you to
                                    steer us in the direction you need our service.
                                    </Card.Text>
                                  </Card.Body>
                                </Card>
                                <Card style={{ width: '22rem', backgroundColor: "#44585b", marginBottom: '1rem' }}>
                                  <Card.Body>
                                    <Card.Title className={`p-3 ${styles.cardH}`}>DAY OF WEDDING COORDINATION</Card.Title>
                                    <Card.Text className={styles.cardText}>
                                    This package is for the couple that has done complete planning of their big day, but needs to
                                    take a sit back and relax allowing them focus on enjoying the bliss moment of the big day
                                    without worrying about their vendors delivery, this is where we come in to manage and
                                    coordinate your vendors, from handling vendors and clients meeting, to creating floor plan and
                                    timeline of activities of the day and ensuring your entire day runs smoothly and your guest fully
                                    satisfied without any glitch or underservice from your vendors.
                                    </Card.Text>
                                  </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='birthday'>
                <Carousel data-bs-theme="dark" controls={false} indicators={false}>
                  <Carousel.Item>
                    <img
                      className={`d-block w-100 ${styles.custom_img}`}
                      src={celebration}
                      alt="birthday slide"
                    />
                    <Carousel.Caption className={styles.custom_caption_birthday}>
                      <h5>Welcome to the Celebration Zone!</h5>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
            </section>
            <section>
                <div className="container text-center py-5 px-3">
                    <div className="row align-items-center justify-conten-center">
                        <div className={`col-md-12 py-4 px-3 ${styles.ourService}`}>
                            <h5>Birthdays</h5>
                        </div>
                        <div className={`col-md-12 py-4 px-3 ${styles.howWe}`}>
                            <h5>
                            We believe that birthdays are not just milestones, but moments to be cherished and celebrated
                            with utmost joy and enthusiasm, be it adult or children's birthday parties, from the moment you
                            envision your dream birthday celebration to the final toast, our dedicated team is here to turn
                            your ideas into reality. Whether you're planning an intimate gathering or a lavish extravaganza,
                            we've got you covered, imagine a magical venue adorned with your favorite colors and themes,
                            and entertainment that keeps the laughter and smiles going all night long. With Perfect Party
                            Planner, your birthday celebration will be nothing short of spectacular
                            </h5>
                        </div>
                    </div>
                </div>
            </section>
            <section id='corporate'>
                <Carousel data-bs-theme="dark" controls={false} indicators={false}>
                  <Carousel.Item>
                    <img
                      className={`d-block w-100 ${styles.custom_img}`}
                      src={corporate}
                      alt="corporate slide"
                    />
                    <Carousel.Caption className={styles.custom_caption_corporate}>
                      <h5>Welcome to the Corporate Connection Hub!</h5>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
            </section>
            <section>
                <div className="container text-center py-5 px-3">
                    <div className="row align-items-center justify-conten-center">
                        <div className={`col-md-12 py-4 px-3 ${styles.ourService}`}>
                            <h5>
                            CORPORATE EVENTS
                            </h5>
                        </div>
                        <div className="col-md-12">
                            <CardGroup className={`text-center`}>
                              <Card className={styles.serveCard}>
                                <Card.Body>
                                  <Card.Title className={`${styles.cardHead}`}>Conference</Card.Title>
                                </Card.Body>
                              </Card>
                              <Card className={styles.serveCard}>
                                <Card.Body>
                                  <Card.Title className={styles.cardHead}>Seminar</Card.Title>
                                </Card.Body>
                              </Card>
                              <Card className={styles.serveCard}>
                                <Card.Body>
                                  <Card.Title className={styles.cardHead}>Product Launch</Card.Title>
                                </Card.Body>
                              </Card>
                              <Card className={styles.serveCard}>
                                <Card.Body>
                                  <Card.Title className={styles.cardHead}>Gala Dinner</Card.Title>
                                </Card.Body>
                              </Card>
                            </CardGroup>
                        </div>
                        <div className={`col-md-12 py-4 px-3 ${styles.howWe}`}>
                        At Perfect Party Planner, we understand the importance of corporate events in fostering
                        connections, celebrating achievements, and driving business success. That's why we're
                        delighted to extend our expertise in creating impactful and memorable corporate experiences
                        tailored to your company's objectives.
                        Picture a sophisticated venue that reflects your company's brand and values, innovative
                        technology solutions that enhance engagement and interaction, and curated experiences that
                        inspire, motivate, and entertain. With Perfect Party Planner, your corporate event will not only
                        meet but exceed expectations, setting the stage for continued success and growth.
                        Let's make your corporate event a resounding success that sets the stage for future
                        achievements!
                        </div>
                    </div>
                </div>
            </section>
            <section  id='intimate'>
                <Carousel data-bs-theme="dark" controls={false} indicators={false}>
                  <Carousel.Item>
                    <img
                      className={`d-block w-100 ${styles.custom_img}`}
                      src={proposal}
                      alt="proposal slide"
                    />
                    <Carousel.Caption className={styles.custom_caption_proposal}>
                      <h5>Welcome to the Intimate Affair Haven!</h5>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
            </section>
            <section>
                <div className="container text-center py-5 px-3">
                    <div className="row align-items-center justify-conten-center">
                        <div className={`col-md-12 py-4 px-3 ${styles.ourService}`}>
                            <h5>
                            INTIMATE EVENTS
                            </h5>
                        </div>
                        <div className="col-md-12">
                            <CardGroup className={`text-center`}>
                              <Card className={styles.serveCard}>
                                <Card.Body>
                                  <Card.Title className={`${styles.cardHead}`}>Proposals</Card.Title>
                                </Card.Body>
                              </Card>
                              <Card className={styles.serveCard}>
                                <Card.Body>
                                  <Card.Title className={styles.cardHead}>Bridal Shower</Card.Title>
                                </Card.Body>
                              </Card>
                              <Card className={styles.serveCard}>
                                <Card.Body>
                                  <Card.Title className={styles.cardHead}>Gender Reveal</Card.Title>
                                </Card.Body>
                              </Card>
                              <Card className={styles.serveCard}>
                                <Card.Body>
                                  <Card.Title className={styles.cardHead}>Child Dedication</Card.Title>
                                </Card.Body>
                              </Card>
                              <Card className={styles.serveCard}>
                                <Card.Body>
                                  <Card.Title className={styles.cardHead}>Surprise Package</Card.Title>
                                </Card.Body>
                              </Card>
                            </CardGroup>
                        </div>
                        <div className={`col-md-12 py-4 px-3 ${styles.howWe}`}>
                            <h5>
                            At Perfect Party Planner, we believe that some of life's most cherished moments are best
                            celebrated in the company of those closest to our hearts.
                            Whether you're planning a cozy dinner party, a romantic engagement celebration, a proposal,
                            surprise birthday and gifting, or a small gathering of friends and family, our dedicated team is
                            here to bring your vision to life with warmth, elegance, and attention to detail. We understand
                            that every intimate event is a reflection of your unique style and personality, and we're
                            committed to ensuring that your special day is nothing short of perfection.
                            </h5>
                        </div>
                    </div>
                </div>
            </section>
            <section id='event_styling'>
                <Carousel data-bs-theme="dark" controls={false} indicators={false}>
                  <Carousel.Item>
                    <img
                      className={`d-block w-100 ${styles.custom_img}`}
                      src={design}
                      alt="event_design slide"
                    />
                    <Carousel.Caption className={styles.custom_caption_design}>
                      <h5>Welcome to the Design & Styling Studio</h5>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className={`d-block w-100 ${styles.custom_img}`}
                      src={design1}
                      alt="event_design slide"
                    />
                    <Carousel.Caption className={styles.custom_caption_design}>
                      <h5>Welcome to the Design & Styling Studio</h5>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className={`d-block w-100 ${styles.custom_img}`}
                      src={design2}
                      alt="event_design slide"
                    />
                    <Carousel.Caption className={styles.custom_caption_design}>
                      <h5>Welcome to the Design & Styling Studio</h5>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
            </section>
            <section>
                <div className="container text-center">
                    <div className="row align-items-center justify-conten-center">
                        <div className={`col-md-12 py-5 px-3 ${styles.ourService}`}>
                            <h5>
                            EVENT DESIGN AND STYLING
                            </h5>
                        </div>
                        <div className={`col-md-12 py-4 px-3 ${styles.howWe}`}>
                            <h5>
                            We believe that every event is an opportunity to create a truly immersive and unforgettable
                            experience that delights the senses and leaves a lasting impression. That's why we're excited to
                            introduce you to our Design & Styling services, where we specialize in crafting bespoke event
                            atmospheres that are as unique as you are.
                            </h5>
                            <h5>
                            Our talented team of designers and stylists are masters at transforming spaces into works of art,
                            seamlessly blending aesthetics, ambiance, and functionality to bring your vision to life. Whether
                            you're dreaming of a whimsical fairy-tale wedding, a sleek and modern corporate gala, or a
                            vintage-inspired birthday party, we'll work closely with you to curate a design concept that
                            captures your style and personality.
                            </h5>
                            <h5>
                            Picture an enchanting setting where every detail – from the floral arrangements and tablescapes
                            to the lighting and decor – is thoughtfully curated to create a cohesive and visually stunning
                            experience. With our keen eye for design trends and attention to detail, we'll ensure that every
                            element of your event reflects your unique vision.
                            </h5>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    </div>
  )
}

export default HowWeWork