import React from 'react'
import { FaFacebookF, FaInstagram } from "react-icons/fa6";
import logo from '../../websitePictures/Logo/finallogo.png'
import styles from './Footer.module.css'

const Footer = () => {
  return (
    <section className={styles.footCon}>
        <div className={`container py-3 px-5`}>
            <div className="row align-items-center justify-content-between">
                <div className={`col-md-6 ${styles.firstCol}`}>
                    <img src={logo} alt="perfect party planner" width={100} />
                    <h5>&copy; 2024, Perfect Party Planner</h5>
                </div>
                <div className={`col-md-6 text-md-end pt-4 ${styles.secCol}`}>
                    <a href="https://facebook.com">
                        <FaFacebookF />
                    </a>
                    <a href="https://www.instagram.com/perfectparty_planner/" target='_blank' rel="noreferrer">
                        <FaInstagram />
                    </a>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Footer