import React from 'react'
import PageNav from '../Navbar/Navbar'
import { Carousel } from 'react-bootstrap'
import logo from '../../websitePictures/Logo/finallogo.png'
import styles from './OtherService.module.css'
import Footer from '../Footer/Footer'

const Social = () => {
  return (
    <div className={styles.wedCon}>
        <PageNav />
        <div className={styles.wedOther}>
            <section id="Social">
                <Carousel  data-bs-theme="dark" controls={false} indicators={false} slide={true} interval={2000}>
                  <Carousel.Item>
                    <video className={`d-block w-100 ${styles.custom_video}`} playsInline autoPlay loop muted>
                      <source src="https://res.cloudinary.com/dglpy94yq/video/upload/v1720992549/social_events_cover_mtuqt4.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <Carousel.Caption className={styles.custom_caption}>
                      <h5>Social event</h5>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
            </section>
            <section>
                <div className={`container-fluid px-5 mb-0 ${styles.package}`}>
                    <div className="row align-items-center justify-content-center text-center">
                        <div className="col-md-12">
                            <img src={logo} alt="perfect party planner" className='img-fluid' />
                        </div>
                        <div className="col-md-10">
                            <p>We believe that birthdays are not just milestones, but moments to be cherished and celebrated with utmost joy and enthusiasm, be it or children's birthday parties, from the moment you envision your dream birthday celebration to the final toast, our dedicated team is here to turn your ideas into reality. Whether you're planning an Social gathering or a lavish extravaganza, we've got you covered, imagine a magical venue adorned with your favorite colors and themes, and entertainment that keeps the laughter and smiles going all night long. With Perfect Party Planner, your birthday celebration will be nothing short of spectacular</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`${styles.packCollection8}`}>
                <div className={`container-fluid px-md-5 mx-md-5 ${styles.cardCon}`} id={styles.birthday}>
                    <div class="row align-items-center justify-content-center text-center">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <h5>Birthday</h5>
                                </div>
                                {/*<div className="col-md-12 px-5 mb-4">
                                    <p>
                                    This package is for the couple that has done complete planning of their big day, but needs to take a sit back and relax allowing them focus on enjoying the bliss moment of the big day without worrying about their vendors delivery, this is where we come in to manage and coordinate your vendors, from handling vendors and clients meeting, to creating floor plan and timeline of activities of the day and ensuring your entire day runs smoothly and your guest fully satisfied without any glitch or underservice from your vendors.
                                    </p>
                                </div>*/}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row align-items-center justify-content-center">
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack21}`}>
                                    <div class={`card-body text-center ${styles.bdyCap}`}>
                                      <h4 class="card-title">birthday</h4>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack22}`}>
                                    <div class={`card-body text-center ${styles.bdyCap}`}>
                                      <h4 class="card-title">birthday</h4>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack23}`}>
                                    <div class={`card-body text-center ${styles.bdyCap}`}>
                                      <h4 class="card-title">birthday</h4>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack30}`}>
                                    <div class={`card-body text-center ${styles.bdyCap}`}>
                                      <h4 class="card-title">birthday</h4>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3 mb-3 mb-sm-0 mt-4">
                                  <div class={`card bg-transparent ${styles.eachPack31}`}>
                                    <div class={`card-body text-center ${styles.bdyCap}`}>
                                      <h4 class="card-title">birthday</h4>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            
            <section className={styles.packCollection5}>
                <div className={`container-fluid px-md-5 mx-md-5 ${styles.cardCon}`}>
                    <div class="row align-items-center justify-content-center text-center">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <h5>baby shower</h5>
                                </div>
                                {/*<div className="col-md-12 px-5 mb-4">
                                    <p>
                                    Thinking of a full bridal package! From conceptualization, to theme creation, venue sourcing, and styling, vendor scouring, to managing and coordination of the total event. Zero stress on you, just take a seat back and relax. You want it luxuriously extravagant, or modestly beautiful. We will curate a bespoke event just the way you want it, for it’s your dream day and your ideas and wishes are valid and respected.
                                    </p>
                                    </div>*/}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row align-items-center justify-content-center">
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack25}`}>
                                    <div class={`card-body text-center ${styles.bdyCap}`}>
                                      <h4 class="card-title">baby shower</h4>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack26}`}>
                                    <div class={`card-body ${styles.bdyCap}`}>
                                      <h4 class="card-title text-center">baby shower</h4>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack27}`}>
                                    <div class={`card-body text-center ${styles.bdyCap}`}>
                                      <h4 class="card-title">baby shower</h4>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`${styles.packCollection7}`}>
                <div className={`container-fluid px-md-5 mx-md-5 ${styles.cardCon}`}>
                    <div class="row align-items-center justify-content-center text-center">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <h5>children's birthday</h5>
                                </div>
                                {/*<div className="col-md-12 px-5 mb-4">
                                    <p>
                                    This package is for the curious couple who wants to handle part of their big day planning, but needs professionalism touch in major areas, and coaching. We are here and available for you to steer us in the direction you need our service.
                                    </p>
                                    </div>*/}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row align-items-center justify-content-center">
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack28}`}>
                                    <div class={`card-body text-center ${styles.bdyCap}`}>
                                      <h4 class="card-title">children's birthday</h4>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack29}`}>
                                    <div class={`card-body text-center ${styles.bdyCap}`}>
                                      <h4 class="card-title">children's birthday</h4>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3 mb-3 mb-sm-0">
                                  <div class={`card bg-transparent ${styles.eachPack24}`}>
                                    <div class={`card-body text-center ${styles.bdyCap}`}>
                                      <h4 class="card-title">children's birthday</h4>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/*<section className={`${styles.packCollection6}`}>
                <div className={`container-fluid px-5 mx-5 ${styles.cardCon}`}>
                    <div class="row align-items-center justify-content-center text-center">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <h5>baby shower</h5>
                                </div>
                                <div className="col-md-12 px-5 mb-4">
                                    <p>
                                    This package is for the couple that has done complete planning of their big day, but needs to take a sit back and relax allowing them focus on enjoying the bliss moment of the big day without worrying about their vendors delivery, this is where we come in to manage and coordinate your vendors, from handling vendors and clients meeting, to creating floor plan and timeline of activities of the day and ensuring your entire day runs smoothly and your guest fully satisfied without any glitch or underservice from your vendors.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row align-items-center justify-content-center">
                            </div>
                        </div>
                    </div>
                </div>
            </section>*/}


            
        <Footer />
        </div>
    </div>
  )
}

export default Social