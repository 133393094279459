import React, { useEffect, useState } from 'react';
import { Container, Nav, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap';
//import { GiHamburgerMenu } from "react-icons/gi";
import { RiMenu4Fill } from "react-icons/ri";
import { Link } from 'react-router-dom';
import styles from './Navbar.module.css'

const OffcanvasExample = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Navbar expand={false} fixed='top' className={`sticky-top ${styles.navbar}`}>
      <Container fluid className="d-flex justify-content-between align-items-center">
        <Navbar.Brand className={`mx-auto ${styles.mx_auto}`} href="#">
          <Link to={"/"}>
            <h5>
              Perfect Party Planner
            </h5>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="offcanvasNavbar"
          as="button"
          style={{ border: "none", fontSize: "36px" }}
          className='navbar-toggler shadow-none bx-menu-button border-none text-white'>
          <RiMenu4Fill />
        </Navbar.Toggle>
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel" className={styles.canvasTitle} >
              <h5>perfect party planner</h5>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className={styles.canvasBdy}>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link>
                <Link to={"/"} className={styles.navLink}>
                  Home
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to={"/about"} className={styles.navLink}>
                  Who we are
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to={"/services"} className={styles.navLink}>
                  How we work
                </Link>
              </Nav.Link>
              <NavDropdown
                title={<Link className={styles.navLink}>Our Services</Link>}
                id="offcanvasNavbarDropdown"
              >
                <NavDropdown.Item href="#action/3.1">
                  <Nav.Link>
                    <Link
                      to={"/wedding"}
                      style={{ textDecoration: "none" }}
                      className={`${styles.navLink} ${scrolled ? styles.navLink : ""}`}
                      onClick={() => scrollToSection("our_services")}
                    >
                      Weddings
                    </Link>
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.1">
                  <Nav.Link>
                    <Link
                      to={"/social"}
                      style={{ textDecoration: "none" }}
                      className={`${styles.navLink} ${scrolled ? styles.navLink : ""}`}
                      onClick={() => scrollToSection("social")}
                    >
                      Social Events
                    </Link>
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.1">
                  <Nav.Link>
                    <Link
                      to={"/corporate"}
                      style={{ textDecoration: "none" }}
                      className={`${styles.navLink} ${scrolled ? styles.navLink : ""}`}
                      onClick={() => scrollToSection("corporate")}
                    >
                      Corporate Events
                    </Link>
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item className="">
                  <Nav.Link>
                    <Link
                      to={"/intimate"}
                      style={{ textDecoration: "none" }}
                      className={`${styles.navLink} ${scrolled ? styles.navLink : ""}`}
                      onClick={() => scrollToSection("birthday")}
                    >
                      Intimate Event
                    </Link>
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.1">
                  <Nav.Link>
                    <Link
                      to={"/design"}
                      style={{ textDecoration: "none" }}
                      className={`${styles.navLink} ${scrolled ? styles.navLink : ""}`}
                      onClick={() => scrollToSection("design")}
                    >
                      Event Design and Decor
                    </Link>
                  </Nav.Link>
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link>
                <Link
                  to={"/contact"}
                  style={{ textDecoration: "none" }}
                  className={`${styles.navLinkBtn} ${scrolled ? styles.navLinkBtn : ""}`}
                >
                  Contact
                </Link>
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default OffcanvasExample;